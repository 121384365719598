.App {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  height: 100%;
  margin: 0;
  font-family: Arial, sans-serif;
  animation: slideFromTop 1s ease-in-out; 
}

/* -------------------------------------------------Loading Overlay----------------------------------------- */
/* Loading Overlay */
.loadingOverlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.7);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 9999;
}

/* Loader */
.loader {
  width: 50px;
  height: 50px;
  border: 5px solid #3498db;
  border-top: 5px solid #1a5276;
  border-radius: 50%;
  animation: spin 1s linear infinite;
}

/* Loader Animation */
@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}